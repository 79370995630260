import logo from './logo.svg';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
  card: {
    maxWidth: 345,
    backgroundColor: "#dfbbb1",
  },
  media: {
    height: 140,
  },
  bigAvatar: {
    margin: 10,
    width: 80,
    height: 80,
  },
  fancy: {
    fontFamily: 'Candara',
  },
  paperMain: {
    //width: "100%",
    backgroundColor: "white",
    margin: 20,
    fontSize: 32,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "left",
  },
  paper: {
    backgroundColor: "#dfbbb1",
    margin: 10,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "left",
  },
  content: {
    margin: 1,
  },
  paperHeader: {
    margin: 10,
    fontFamily: 'Candara',
  },
  paperContent: {
    margin: 20,
  },
  paperSmall: {
    margin: 10,
    fontSize: 18
  },
  rule: {
    width: "85%",
  },
  button: {

  }
});

function App() {
  const classes = useStyles();

  return (
    <div className="App">
    <header className="App-header">
      <Avatar alt="Get Lost Logo" src="pic.jpg" className={classes.bigAvatar} />
      <h1 className={classes.fancy}>GET LOST</h1>
    </header>
    <div className="App-main">
      <Grid container className={classes.content}>
        <Grid item xs={12} md={12} lg={12} xl={4}></Grid>
          <Grid item xs={12} md={12} lg={12} xl={4}>
            <Paper elevation={0} className={classes.paperMain}>
              <p className={classes.paperContent}>
                <b>Get Lost</b> is a competitive racing game. 
                Each player races from the trail head to the campsite, using gear and food they find along the way to help overcome obstacles on the trail!
              </p>
              <p className={classes.paperContent}>
                The base rules are simple: A new trail card is revealed, each player plays a card to react to it, then keeps hiking along the trail. 
              </p>
              <p className={classes.paperContent}>
                Can you make it to the campsite first, or will you lag behind to hoarde all the food?
                With many achievements each game, who's to say what the right strategy is on the trail?
              </p>
            </Paper>
          </Grid>
        <Grid item xs={12} md={12} lg={12} xl={4}></Grid>
      </Grid>
      <Grid container className={classes.content}>
        <Grid item xs={12} md={12} lg={12} xl={4}></Grid>
          <Grid item xs={12} md={12} lg={12} xl={4}>
            <Paper elevation={3} className={classes.paperMain}>
            <header className={classes.paperHeader}><h2>STAY IN THE LOOP</h2></header>
              <p className={classes.paperContent}>
                Give us your email and we'll let you know when the game is released!
              </p>
              <p>
                <TextField id="email" style={{ margin:8, width: "80%" }} label="Email Address" size="normal" />
              </p>
              <Button className={classes.button} variant="outlined" color="primary" disabled>
                Loop me in
              </Button>
              <p className={classes.paperSmall}>
                We'll only use your email for this purpose, and never give it anyone else
              </p>
            </Paper>
          </Grid>
        <Grid item xs={12} md={12} lg={12} xl={4}></Grid>
      </Grid>
    </div>
  </div>
  );
}

export default App;
